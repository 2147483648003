/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { getOut } from "library/services/userApi";

import { history } from "core/store/configureStore";

import styles from "./index.module.scss";

import menu from "resources/img/menu.svg";
import userImg from "resources/img/user.svg";
import lk from "resources/img/lk.svg";
import exit from "resources/img/exit.svg";
import plus from "resources/img/add.svg";
import { message } from "library/common/selectors/shellSelectors";
interface IUser {
  firstName: string;
  lastName: string;
  id: number;
}

interface IHeader {
  openMenuAction: () => void;
  logOutAction: () => void;
  isMenuOpen: boolean;
  user: IUser;
  addBtn: Function;
  showMsg: (data: any) => void;
}

const Header: React.FC<IHeader> = ({
  openMenuAction,
  logOutAction,
  isMenuOpen,
  user,
  addBtn,
  showMsg,
}: IHeader) => {
  const [menuLk, setMenuLk] = useState<boolean>(false);

  const message = { errorColor: true, msg: "Что-то пошло не так", show: true };

  useEffect(() => {
    if (isMenuOpen) {
      openMenuAction();
      setMenuLk(false);
    }
    if (menuLk) {
      setMenuLk(false);
    }
  }, [window.location.pathname]);

  function handleMenuOpen() {
    openMenuAction();
  }

  function handleTitles() {
    switch (history.location.pathname) {
      case "/clients":
        return "Клиенты";
      case "/members":
        return "Сотрудники";
      case "/boards":
        return "";
      case "/archive":
        return "архив";
      case "/basket":
        return "корзина";
      case "/personal":
        return "Личный кабинет";
      default:
        return "relife";
    }
  }

  function goOut() {
    getOut()
      .then(() => {
        logOutAction();
      })
      .catch(() => {
        showMsg(message);
      });
  }

  return (
    <div className={styles.grid}>
      {isMenuOpen && (
        <div className={styles.overlay} onClick={() => handleMenuOpen()} />
      )}
      {menuLk && (
        <div className={styles.overlayLk} onClick={() => setMenuLk(false)} />
      )}
      <div className={styles.wrap}>
        <div className={styles.btn} onClick={handleMenuOpen}>
          <SVG src={menu} className={styles.btn__menu} />
        </div>
        <div className={styles.btn} onClick={() => addBtn()}>
          <SVG src={plus} className={styles.btn__add} />
        </div>
      </div>
      <div className={styles.title}>{handleTitles()}</div>
      <div className={styles.wrapper} onClick={() => setMenuLk(true)}>
        <div className={styles.name}>
          {user.firstName + " " + user.lastName}
        </div>
        <SVG src={userImg} className={styles.user} />
        {menuLk && (
          <div className={styles.lk}>
            <Link to="/personal" className={styles.lk__item}>
              <SVG src={lk} className={styles.lk__ico} />
              <div className={styles.lk__title}>Личный кабинет</div>
            </Link>
            <div className={styles.lk__item} onClick={() => goOut()}>
              <SVG src={exit} className={styles.lk__img} />
              <div className={styles.lk__title}>Выход</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
